.ant-select {
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  color: var(--sally-input-text);
  font-size: 14px;
  line-height: 1.5;

  > ul > li > a {
    padding: 0;
    background-color: var(--sally-input-background);
  }
  .ant-select-arrow {
    display: block;
    margin-top: 2px;
  }
  &.ant-select-compact .ant-select-selection {
    padding-left: 8px;
    padding-right: 8px;
  }
  .ant-select-selection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 4px 12px;
    outline: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-sizing: border-box;
    background-color: var(--sally-input-background);
    border-radius: 4px;
    border: 1px solid var(--sally-input-border);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    &:hover {
      border-color: var(--sally-input-hover-border);

      .ant-select-selection__clear {
        opacity: 1;
      }
    }
    .ant-select-selection__clear {
      display: inline-block;
      font-style: normal;
      vertical-align: baseline;
      text-align: center;
      text-transform: none;
      text-rendering: auto;
      opacity: 0;
      position: absolute;
      right: 16px;
      z-index: 1;
      top: 40%;
      font-size: 12px;
      width: 12px;
      height: 12px;
      margin-top: -6px;
      line-height: 12px;
      cursor: pointer;
      transition: color 0.3s ease, opacity 0.15s ease;
      background-color: var(--sally-input-background);

      .icon-fill {
        fill: var(--sally-input-text);
      }

      &:hover .icon-fill {
        // nothing for now
      }

      .ant-select-selection-selected-value {
        float: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
        padding-right: 14px;
      }
    }
  }
  &.ant-select-focused .ant-select-selection,
  .ant-select-selection:focus,
  .ant-select-selection:active {
    border-color: var(--sally-input-focus-border);
    outline: 0;
    box-shadow: 0 2px 4px 0 var(--sally-input-focus-shadow);
  }

  &.ant-select-disabled {
    color: var(--sally-input-disabled-text);

    .ant-select-selection {
      background-color: var(--sally-input-disabled-background);
      border-color: var(--sally-input-disabled-border);

      &:hover, &:focus, &:active {
        border-color: var(--sally-input-disabled-border);
        box-shadow: none;
      }
    }
    .ant-select-selection__clear {
      display: none;
      visibility: hidden;
      pointer-events: none;
    }

    .ant-select-selection__choice__remove {
      color: var(--sally-input-disabled-text);
      cursor: default;

      &:hover {
        color: var(--sally-input-disabled-text);
      }
    }
  }
  .ant-select-selection--single {
    position: relative;
    cursor: pointer;
  }
  .ant-select-selection__rendered {
    display: block;
    position: relative;
    height: 100%;
    text-wrap: nowrap;
    overflow: hidden;
  }

  height: var(--sally-input-m-height);

  .ant-select-selection--single, .ant-select-selection {
    height: var(--sally-input-m-height);
  }

  &.ant-select-l {
    height: var(--sally-input-l-height);

    .ant-select-selection--single, .ant-select-selection {
      height: var(--sally-input-l-height);
    }

    .ant-select-selection {
      padding: 8px 12px;
    }
  }

  &.ant-select-xl {
    height: var(--sally-input-xl-height);

    .ant-select-selection--single, .ant-select-selection {
      height: var(--sally-input-xl-height);
    }

    .ant-select-selection {
      padding: 8px 12px;
    }
  }
  .ant-select-search--inline {
    // position: absolute;
    height: 100%;
    width: 100%;
    .ant-select-search__field__wrap {
      display: inline-block;
      // position: relative;

      // .ant-select-search__field__mirror {
      //   position: absolute;
      //   top: 0;
      //   left: -9999px;
      //   white-space: pre;
      //   pointer-events: none;
      // }
      .ant-select-search__field__wrap {
        width: 100%;
        height: 100%;
      }
      .ant-select-search__field {
        border-width: 0;
        font-size: 100%;
        height: 100%;
        width: 100%;
        background: transparent;
        outline: 0;
        border-radius: 4px;
        line-height: 1;
      }
      > i {
        float: right;
      }
    }
  }
  .ant-select-selection__placeholder,
  .ant-select-search__field__placeholder {
    color: var(--sally-input-placeholder-text);
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
  }
  .ant-select-search__field__placeholder {
    left: 8px;
  }
  &.ant-select-open {
    .ant-select-arrow {
      -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
      -ms-transform: rotate(180deg);
    }
    .ant-select-arrow:before {
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    .ant-select-selection {
      border-color: var(--sally-input-focus-border);
      outline: 0;
    }
  }
  &.ant-select-combobox {
    .ant-select-arrow {
      display: none;
    }
    .ant-select-search--inline {
      height: 100%;
      width: 100%;
      float: none;
    }
    .ant-select-search__field__wrap {
      width: 100%;
      height: 100%;
      .ant-select-search__field {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        box-shadow: none;
      }
    }
    &.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered {
      margin-right: 20px;
    }
  }
  @at-root .ant-col & {
    width: 100%;
  }
}
.ant-select-dropdown {
  background-color: var(--sally-input-background);
  border: 1px solid var(--sally-input-border);
  box-shadow: 0 2px 4px 0 var(--sally-input-focus-shadow);
  border-radius: 4px;
  box-sizing: border-box;
  z-index: 1050;
  left: -9999px;
  top: -9999px;
  position: absolute;
  outline: none;
  overflow: hidden;
  font-size: 12px;

  &.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
  &.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
    animation-name: antSlideUpIn;
  }
  &.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topLeft,
  &.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topLeft {
    animation-name: antSlideDownIn;
  }
  &.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
    animation-name: antSlideUpOut;
  }
  &.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topLeft {
    animation-name: antSlideDownOut;
  }
  &.ant-select-dropdown-hidden {
    display: none;
  }
  .ant-select-dropdown-menu {
    outline: none;
    margin-bottom: 0;
    padding: var(--sally-select-padding);
    list-style: none;
    max-height: 250px;
    overflow: auto;

    .ant-select-dropdown-menu-item-group-list {
      margin: 0;
      padding: 0;
      > .ant-select-dropdown-menu-item {
        padding-left: 16px;
      }
    }
    .ant-select-dropdown-menu-item-group-title {
      color: var(--sally-input-text);
      line-height: 1.5;
      padding: 8px;
    }
    .ant-select-dropdown-menu-item {
      position: relative;
      display: block;
      padding: 7px 8px;
      font-weight: normal;
      color: var(--sally-input-text);
      white-space: nowrap;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: background 0.3s ease;
      border-radius: var(--sally-select-item-radius);

      &:not(:last-child) {
        border-bottom: 1px solid var(--sally-select-item-divider);
      }

      &.ant-select-dropdown-menu-item-selected,
      &.ant-select-dropdown-menu-item-selected:hover {
        background-color: var(--sally-select-item-selected-background);
      }
      &.ant-select-dropdown-menu-item-active {
        background-color: var(--sally-select-item-selected-background);
      }

      &:hover, &.ant-select-dropdown-menu-item-selected:hover {
        color: var(--sally-select-item-hover-text);
        background-color: var(--sally-select-item-hover-background);
      }

      &.ant-select-dropdown-menu-item-disabled {
        color: var(--sally-input-disabled-text);
        background-color: var(--sally-input-disabled-background);
        cursor: not-allowed;

        &:hover {
          color: var(--sally-input-disabled-text);
          background-color: var(--sally-input-disabled-background);
        }
      }
    }
    &.ant-select-dropdown-menu-item-divider {
      height: 1px;
      margin: 1px 0;
      overflow: hidden;
      background-color: var(--sally-select-item-divider);
      line-height: 0;
    }
  }
  &.ant-select-dropdown-long .ant-select-dropdown-menu {
    max-height: 70vh;
  }
}
.ant-select-dropdown-container-open .ant-select-dropdown,
.ant-select-dropdown-open .ant-select-dropdown {
  display: block;
}