.companies-single .companies-single-main {
  max-width: 1240px;
  margin-right: auto;
  margin-left: auto;
  padding: 55px 0 0 0;
}
.companies-single .companies-single-main .companies-single-form {
  position: relative;
  margin-bottom: 20px;
}
.companies-single .companies-single-main .companies-single-form .ant-btn.ant-btn-secondary {
  width: auto;
}
.companies-single .companies-single-main .h1 {
  margin-top: 0;
}
.companies-single .companies-single-main .title-menu a + *, .companies-single .companies-single-main .title-menu .ant-btn + * {
  margin-left: 20px;
}
.companies-single .companies-single-main .ant-spin-nested-loading {
  margin-bottom: 0;
}
.companies-single .companies-single-main .ant-card.ant-card-bordered.no-contract-card .ant-card-body, .companies-single .companies-single-main .ant-card.ant-card-bordered.has-package-card .ant-card-body {
  background-position: 30px center;
  background-repeat: no-repeat;
  background-size: 40px 40px;
}
.companies-single .companies-single-main .ant-card.ant-card-bordered.no-contract-card .ant-card-body p, .companies-single .companies-single-main .ant-card.ant-card-bordered.has-package-card .ant-card-body p {
  margin: 0;
  font-size: 14px;
}
.companies-single .companies-single-main .ant-card.ant-card-bordered.no-contract-card .ant-card-body a, .companies-single .companies-single-main .ant-card.ant-card-bordered.has-package-card .ant-card-body a {
  position: absolute;
  top: 50%;
  right: 40px;
  display: inline-block;
  line-height: 20px;
  margin-top: -10px;
  padding-right: 15px;
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 12px 12px;
  font-size: 14px;
  font-weight: 500;
}
.companies-single .companies-single-main .ant-card.ant-card-bordered.no-contract-card .ant-card-body, .companies-single .companies-single-main .ant-card.ant-card-bordered.no-contract-card:hover .ant-card-body, .companies-single .companies-single-main .ant-card.ant-card-bordered.has-package-card .ant-card-body, .companies-single .companies-single-main .ant-card.ant-card-bordered.has-package-card:hover .ant-card-body {
  padding: 25px 110px 25px 90px;
}
.companies-single .companies-single-main .ant-card.ant-card-bordered.has-package-card .ant-card-body {
  background-position: 30px center;
  background-repeat: no-repeat;
  background-size: 40px 40px;
}
.companies-single .companies-single-main .ant-card.ant-card-bordered.has-package-card .ant-card-body p {
  margin: 0;
  font-size: 14px;
}
.companies-single .companies-single-main .ant-card.ant-card-bordered.has-package-card .ant-card-body a, .companies-single .companies-single-main .ant-card.ant-card-bordered.has-package-card .ant-card-body .ant-btn {
  position: absolute;
  top: 50%;
  right: 40px;
  margin-top: -10px;
}
.companies-single .companies-single-main .ant-card.ant-card-bordered.has-package-card .ant-card-body a {
  display: inline-block;
  line-height: 20px;
  padding-right: 15px;
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 12px 12px;
  font-size: 14px;
  font-weight: 500;
}
.companies-single .companies-single-main .ant-card.ant-card-bordered.has-package-card .ant-card-body .ant-btn {
  margin-top: -18px;
  padding-right: 14px;
}
.companies-single .companies-single-main .ant-card.ant-card-bordered.has-package-card .ant-card-body, .companies-single .companies-single-main .ant-card.ant-card-bordered.has-package-card:hover .ant-card-body {
  padding: 25px 170px 25px 90px;
}
.companies-single .companies-single-main .ant-card.ant-card-bordered.package-base-card .ant-card-body .package-base-card-header .package-base-card-title, .companies-single .companies-single-main .ant-card.ant-card-bordered.package-base-card:hover .ant-card-body .package-base-card-header .package-base-card-title {
  font-size: 20px;
  font-weight: 700;
}
.companies-single .companies-single-main .ant-card.ant-card-bordered.package-base-card .ant-card-body .package-base-card-header .package-base-card-title em, .companies-single .companies-single-main .ant-card.ant-card-bordered.package-base-card:hover .ant-card-body .package-base-card-header .package-base-card-title em {
  font-style: normal;
  color: var(--sally-ad-highlight);
}
.companies-single .companies-single-main .ant-card.ant-card-bordered.package-base-card .ant-card-body .package-base-card-header .package-base-card-subtitle, .companies-single .companies-single-main .ant-card.ant-card-bordered.package-base-card:hover .ant-card-body .package-base-card-header .package-base-card-subtitle {
  font-size: 16px;
  color: var(--sally-grey-shaded);
}
.companies-single .companies-single-main .ant-card.ant-card-bordered.package-base-card .ant-card-body .package-base-card-header .package-base-card-info, .companies-single .companies-single-main .ant-card.ant-card-bordered.package-base-card:hover .ant-card-body .package-base-card-header .package-base-card-info {
  float: right;
}
.companies-single .companies-single-main .ant-card.ant-card-bordered.package-base-card .ant-card-body .package-base-card-header .package-base-card-info .ant-btn, .companies-single .companies-single-main .ant-card.ant-card-bordered.package-base-card:hover .ant-card-body .package-base-card-header .package-base-card-info .ant-btn {
  margin: 5px 0 0 20px;
}
.companies-single .companies-single-main .ant-card.ant-card-bordered.package-base-card .ant-card-body .package-base-card-header .package-base-card-info a, .companies-single .companies-single-main .ant-card.ant-card-bordered.package-base-card:hover .ant-card-body .package-base-card-header .package-base-card-info a {
  font-size: 15px;
}
.companies-single .companies-single-main .ant-card.ant-card-bordered.package-base-card .ant-card-body .ant-row, .companies-single .companies-single-main .ant-card.ant-card-bordered.package-base-card:hover .ant-card-body .ant-row {
  margin-top: 40px;
}
.companies-single .companies-single-main .ant-card.ant-card-bordered.package-base-card .ant-card-body .ant-row > *, .companies-single .companies-single-main .ant-card.ant-card-bordered.package-base-card:hover .ant-card-body .ant-row > * {
  font-size: 15px;
}
.companies-single .companies-single-main .ant-card.ant-card-bordered.package-base-card .ant-card-body .ant-row > * strong, .companies-single .companies-single-main .ant-card.ant-card-bordered.package-base-card:hover .ant-card-body .ant-row > * strong {
  display: block;
  margin-bottom: 5px;
  border-bottom: 1px solid var(--sally-ad-highlight);
  font-weight: 700;
  font-size: 17px;
  color: var(--sally-ad-highlight);
}
.companies-single .companies-single-main .ant-card.ant-card-bordered.package-addon-card .ant-card-body {
  height: 150px;
}
.companies-single .companies-single-main .ant-card.ant-card-bordered.package-addon-card .ant-card-body .ant-switch-wrapper, .companies-single .companies-single-main .ant-card.ant-card-bordered.package-addon-card .ant-card-body .ant-link-box {
  position: absolute;
  top: 50%;
  left: 10px;
  margin-top: -50px;
  padding: 30px 50px;
  border-right: 1px solid var(--sally-card-border);
}
.companies-single .companies-single-main .ant-card.ant-card-bordered.package-addon-card .ant-card-body .ant-link-box {
  margin-top: -60px;
  padding: 30px 10px;
  font-size: 14px;
  width: 150px;
  box-sizing: border-box;
  text-align: center;
}
.companies-single .companies-single-main .ant-card.ant-card-bordered.package-addon-card .ant-card-body .h2 {
  margin: 5px 0 0 0;
  font-weight: 500;
}
.companies-single .companies-single-main .ant-card.ant-card-bordered.package-addon-card .ant-card-body .h2 span {
  font-weight: 300;
}
.companies-single .companies-single-main .ant-card.ant-card-bordered.package-addon-card .ant-card-body p {
  margin: 0;
  line-height: 22px;
  font-size: 14px;
}
.companies-single .companies-single-main .ant-card.ant-card-bordered.package-addon-card .ant-card-body p a {
  font-size: 16px;
  font-weight: 500;
  color: var(--sally-ad-highlight);
}
.companies-single .companies-single-main .ant-card.ant-card-bordered.package-addon-card .ant-card-body .package-addon-card-icon {
  position: absolute;
  top: 50%;
  right: 220px;
  margin-top: -23px;
  width: 46px;
  height: 46px;
}
.companies-single .companies-single-main .ant-card.ant-card-bordered.package-addon-card .ant-card-body .package-addon-card-price {
  position: absolute;
  top: 50%;
  right: 10px;
  width: 185px;
  margin-top: -50px;
  padding: 15px 30px;
  border-left: 1px solid var(--sally-card-border);
  font-size: 16px;
}
.companies-single .companies-single-main .ant-card.ant-card-bordered.package-addon-card .ant-card-body .package-addon-card-price span {
  font-weight: 500;
  color: var(--sally-ad-highlight-price);
}
.companies-single .companies-single-main .ant-card.ant-card-bordered.package-addon-card .ant-card-body .package-addon-card-price em {
  font-size: 12px;
  font-style: normal;
}
.companies-single .companies-single-main .ant-card.ant-card-bordered.package-addon-card .ant-card-body .package-addon-card-price.price-four-lines {
  line-height: 20px;
}
.companies-single .companies-single-main .ant-card.ant-card-bordered.package-addon-card .ant-card-body, .companies-single .companies-single-main .ant-card.ant-card-bordered.package-addon-card:hover .ant-card-body {
  padding: 25px 400px 25px 210px;
}
.companies-single .h3 {
  line-height: inherit;
}
.companies-single .h3 > .user-image {
  float: left;
  margin: 3px 18px 0 0;
}
.companies-single .h3 a {
  display: inline-block;
  padding: 5px 0;
  font-size: 10pt;
}

.company-logo {
  position: relative;
}
.company-logo .logo {
  margin: 0 auto 20px auto;
  width: 308px;
  border: 4px solid var(--sally-card-border);
}
.company-logo .logo img {
  max-width: 300px;
}
.company-logo .ant-upload.ant-upload-drag {
  display: inline-block;
  width: auto;
  height: auto;
}
.company-logo .ant-btn {
  margin-right: 10px;
  margin-left: 10px;
  padding-right: 14px;
}

.excel-employees-import .ant-alert {
  margin-top: 20px;
  margin-bottom: 0px;
}
.excel-employees-import a + .ant-btn, .excel-employees-import .ant-btn + .ant-btn {
  margin-left: 10px;
  padding-left: 20px;
  font-size: 14px;
}
.excel-employees-import .ant-upload.ant-upload-drag {
  display: inline-block;
  width: inherit;
}
.excel-employees-import .preview-table {
  font-size: 14px;
}
.excel-employees-import .preview-table .errors {
  margin-top: -10px;
  margin-bottom: 20px;
}
.excel-employees-import .preview-table .ant-row {
  margin-bottom: 10px;
}

.vacation-calendar-form {
  max-width: 600px;
}
.vacation-calendar-form .vacation-set-active {
  float: right;
}
.vacation-calendar-form .calendar-add-day-form {
  margin-top: 10px;
}
.vacation-calendar-form .calendar-add-day-form .calendar-add-day {
  display: inline-block;
  margin-top: 2px;
  font-size: 14px;
  font-weight: 500;
  color: var(--sally-link);
  cursor: pointer;
}
.vacation-calendar-form .calendar-add-day-form .calendar-add-day .icon {
  margin: 0 2px -5px 0;
}

.auto-approve-reimbursement-employees .reimbursement-limit-employee {
  width: 300px;
}
.auto-approve-reimbursement-employees .reimbursement-limit-amount {
  max-width: 70%;
}
.auto-approve-reimbursement-employees .ant-table-row {
  min-height: 50px;
}
.auto-approve-reimbursement-employees .ant-table-row .reimbursement-limit-amount .reimbursement-limit-display {
  cursor: pointer;
}
.auto-approve-reimbursement-employees .ant-table-row .reimbursement-limit-amount .reimbursement-limit-form {
  display: flex;
  justify-content: space-between;
}
.auto-approve-reimbursement-employees .ant-table-row .reimbursement-limit-amount .reimbursement-limit-form > * {
  flex-grow: 0.5;
}
.auto-approve-reimbursement-employees .ant-table-row .reimbursement-limit-amount .reimbursement-limit-form .reimbursement-limit-buttons {
  display: flex;
  justify-content: flex-end;
}
.auto-approve-reimbursement-employees .ant-table-row .reimbursement-limit-amount .reimbursement-limit-form .reimbursement-limit-buttons .reimbursement-limit-remove {
  min-width: 100px;
  padding: 0 15px;
}
.auto-approve-reimbursement-employees .ant-table-row .reimbursement-limit-amount .reimbursement-limit-form .reimbursement-limit-buttons .ant-btn {
  margin-top: 25px !important;
}
.auto-approve-reimbursement-employees .ant-table-row .reimbursement-limit-amount .reimbursement-limit-form .reimbursement-limit-buttons .reimbursement-limit-toggle {
  margin-top: 32px;
}

.companies-single .advanced-settings .ant-col.setting-cell:target, .companies-single .advanced-settings .ant-col.setting-cell.target-element {
  border-radius: 5px;
  background-color: var(--sally-target-element-background);
}
.companies-single .advanced-settings .ant-col.setting-cell:target, .companies-single .advanced-settings .ant-col.setting-cell:target p, .companies-single .advanced-settings .ant-col.setting-cell:target label, .companies-single .advanced-settings .ant-col.setting-cell.target-element, .companies-single .advanced-settings .ant-col.setting-cell.target-element p, .companies-single .advanced-settings .ant-col.setting-cell.target-element label {
  color: var(--sally-target-element-text);
}

