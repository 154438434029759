.pay-rolls-single .pay-rolls-single-menu {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100px;
  padding: 0 20px;
  background-color: var(--sally-background-layer-1);
  border-bottom: 1px solid var(--sally-card-border);
  box-shadow: 0 2px 6px 0 rgba(28, 75, 110, 0.04);
}
.pay-rolls-single .pay-rolls-single-menu .h1 {
  margin: 0;
  line-height: 100px;
  vertical-align: middle;
  color: var(--sally-text);
}
.pay-rolls-single .pay-rolls-single-menu .h1 a {
  display: inline-block;
  padding-left: 20px;
  font-size: 14px;
}
.pay-rolls-single .pay-rolls-single-menu .title-menu {
  position: relative;
  min-width: 300px;
  min-height: 72px;
  margin-top: 14px;
  text-align: right;
}
.pay-rolls-single .pay-rolls-single-menu .title-menu .ant-btn {
  vertical-align: middle;
  margin-left: 10px;
}
.pay-rolls-single .pay-rolls-single-menu .title-menu .ant-btn.ant-btn-primary {
  min-width: 200px;
  margin-left: 30px;
}
.pay-rolls-single .pay-rolls-single-menu .title-menu .ant-btn.ant-btn-secondary {
  margin-left: 30px;
}
.pay-rolls-single .pay-rolls-single-menu .title-menu .loading-overlay {
  top: 0;
  right: -10px;
  bottom: 0;
  left: -10px;
}
.pay-rolls-single .pay-rolls-single-menu .title-menu .payroll-buttons-text {
  position: absolute;
  right: 0;
  bottom: -16px;
  font-size: 12px;
}
.pay-rolls-single .pay-rolls-single-menu .title-menu .ant-btn + p, .pay-rolls-single .pay-rolls-single-menu .title-menu p + .ant-btn {
  margin-left: 10px;
}
.pay-rolls-single .pay-rolls-single-menu .title-menu > span {
  display: block;
  padding-top: 4px;
  font-size: 12px;
}
.pay-rolls-single .pay-rolls-single-main {
  margin-right: auto;
  margin-left: auto;
  padding: 105px 0 0 0;
}
.pay-rolls-single .pay-rolls-single-main .pay-roll-overview .ant-card.ant-card-bordered .ant-card-body {
  min-height: 78px;
  padding-top: 25px;
  padding-bottom: 25px;
}
.pay-rolls-single .pay-rolls-single-main .pay-roll-overview .ant-card.ant-card-bordered .ant-card-body .ant-row > * {
  line-height: 28px;
  font-size: 14px;
  color: var(--sally-paragraph-text);
}
.pay-rolls-single .pay-rolls-single-main .pay-roll-overview .ant-card.ant-card-bordered .ant-card-body .ant-row > * strong {
  font-weight: 500;
  color: var(--sally-text);
}
.pay-rolls-single .pay-rolls-single-main .pay-roll-overview .ant-card.ant-card-bordered .ant-card-body .user-image {
  margin-right: 20px;
  vertical-align: middle;
}
.pay-rolls-single .pay-rolls-single-main .pay-roll-overview .ant-card.ant-card-bordered .ant-card-body a, .pay-rolls-single .pay-rolls-single-main .pay-roll-overview .ant-card.ant-card-bordered .ant-card-body button, .pay-rolls-single .pay-rolls-single-main .pay-roll-overview .ant-card.ant-card-bordered .ant-card-body span.fake-a {
  display: inline-block;
}
.pay-rolls-single .pay-rolls-single-main .pay-roll-overview .ant-card.ant-card-bordered .ant-card-body a .disabled, .pay-rolls-single .pay-rolls-single-main .pay-roll-overview .ant-card.ant-card-bordered .ant-card-body button .disabled, .pay-rolls-single .pay-rolls-single-main .pay-roll-overview .ant-card.ant-card-bordered .ant-card-body span.fake-a .disabled {
  cursor: not-allowed;
}
.pay-rolls-single .pay-rolls-single-main .pay-roll-overview .ant-card.ant-card-bordered .ant-card-body a + a, .pay-rolls-single .pay-rolls-single-main .pay-roll-overview .ant-card.ant-card-bordered .ant-card-body a + button, .pay-rolls-single .pay-rolls-single-main .pay-roll-overview .ant-card.ant-card-bordered .ant-card-body a + span.fake-a, .pay-rolls-single .pay-rolls-single-main .pay-roll-overview .ant-card.ant-card-bordered .ant-card-body button + a, .pay-rolls-single .pay-rolls-single-main .pay-roll-overview .ant-card.ant-card-bordered .ant-card-body button + button, .pay-rolls-single .pay-rolls-single-main .pay-roll-overview .ant-card.ant-card-bordered .ant-card-body button + span.fake-a, .pay-rolls-single .pay-rolls-single-main .pay-roll-overview .ant-card.ant-card-bordered .ant-card-body span.fake-a + a, .pay-rolls-single .pay-rolls-single-main .pay-roll-overview .ant-card.ant-card-bordered .ant-card-body span.fake-a + button, .pay-rolls-single .pay-rolls-single-main .pay-roll-overview .ant-card.ant-card-bordered .ant-card-body span.fake-a + span.fake-a {
  margin-left: 20px;
}
.pay-rolls-single .pay-rolls-single-main .pay-roll-overview .pay-roll-toggle {
  display: inline-block;
  padding: 0 20px 0 0;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.pay-rolls-single .pay-rolls-single-main .pay-roll-overview .pay-roll-card-container {
  overflow-y: hidden;
  max-height: 0;
  margin: 0 -30px -25px -30px;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.pay-rolls-single .pay-rolls-single-main .pay-roll-overview .pay-roll-card-container.pay-roll-card-container-visible {
  overflow-y: visible;
  max-height: 25000px;
}
.pay-rolls-single .pay-rolls-single-main .pay-roll-overview .pay-roll-card-buttons {
  margin: 15px 15px 0;
}
.pay-rolls-single .pay-rolls-single-main .pay-roll-overview .pay-roll-table > p {
  margin: 0 30px;
}
.pay-rolls-single .pay-rolls-single-main .pay-roll-overview .pay-roll-table .pay-roll-table-actions {
  width: 1px;
  white-space: nowrap;
}
.pay-rolls-single .pay-rolls-single-main .pay-roll-overview .pay-roll-table .ant-spin-nested-loading {
  margin-top: 30px;
  margin-bottom: 0;
}
.pay-rolls-single .pay-rolls-single-main .pay-roll-overview .pay-roll-table .ant-table {
  max-height: 1500px;
  overflow-y: scroll;
  margin: 0;
}
.pay-rolls-single .pay-rolls-single-main .pay-roll-details .h2 {
  margin-bottom: 15px;
}
.pay-rolls-single .pay-rolls-single-main .pay-roll-details .ant-row {
  line-height: 32px;
  font-size: 14px;
  font-weight: 500;
}
.pay-rolls-single .pay-rolls-single-main .pay-roll-details .ant-row em {
  font-style: normal;
  font-weight: 400;
}
.pay-rolls-single .pay-rolls-single-main .pay-roll-details .ant-row .icon {
  margin: 0 10px;
  vertical-align: middle;
}
.pay-rolls-single .pay-rolls-single-main .pay-roll-details .ant-row + .ant-row {
  margin-top: 0;
}
.pay-rolls-single .pay-rolls-single-main .pay-roll-details .ant-row > *:first-child {
  font-weight: 400;
}
.pay-rolls-single .pay-rolls-single-main .pay-roll-details .ant-row > *:last-child {
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.pay-rolls-single .pay-rolls-single-main .pay-roll-details .ant-row .ant-btn-icon {
  margin-left: 4px;
}
.pay-rolls-single .pay-rolls-single-main .pay-roll-details .ant-row .ant-btn-icon .icon {
  margin: unset;
}
.pay-rolls-single .pay-rolls-single-main .pay-roll-details .disposition-date {
  display: flex;
}
.pay-rolls-single .pay-rolls-single-main .pay-roll-details .approved-by-at {
  margin-top: -8px;
  display: block;
  font-style: italic;
  font-weight: normal;
}
.pay-rolls-single .pay-rolls-single-main .pay-roll-timeline .h2 {
  margin-bottom: 30px;
}
.pay-rolls-single .pay-rolls-single-main .warnings-container {
  display: none;
  margin-top: 10px;
}
.pay-rolls-single .pay-rolls-single-main .warnings-container.visible {
  display: block;
}
.pay-rolls-single .pay-rolls-single-main .warnings-container > .ant-alert > .ant-alert-message > .ant-row > .ant-col {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}

.pay-roll-tax-report .h1 {
  margin-top: 0;
}
.pay-roll-tax-report .h3 {
  margin-bottom: -20px;
}
.pay-roll-tax-report .ant-row > * {
  font-size: 14px;
}
.pay-roll-tax-report .ant-row > * strong {
  font-weight: 500;
}
.pay-roll-tax-report .ant-row + .ant-row {
  margin-top: 30px;
}

.pay-roll-transfers .h1 {
  margin-top: 0;
}
.pay-roll-transfers .pay-roll-table {
  margin: 0 -30px -25px -30px;
}
.pay-roll-transfers .pay-roll-table .pay-roll-table-actions {
  width: 1px;
  white-space: nowrap;
}
.pay-roll-transfers .pay-roll-table .ant-spin-nested-loading {
  margin-top: 30px;
  margin-bottom: 0;
}
.pay-roll-transfers .pay-roll-table .ant-table {
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.pay-roll-transfers .pay-roll-table .ant-table .ant-table-body > table {
  padding: 0;
}
.pay-roll-transfers .pay-roll-table .ant-table .ant-table-body .ant-table-thead > tr > th {
  padding: 11px 20px;
  font-size: 14px;
  font-weight: 400;
}
.pay-roll-transfers .pay-roll-table .ant-table .ant-table-body .ant-table-thead > tr > th:first-child {
  padding-left: 30px;
}
.pay-roll-transfers .pay-roll-table .ant-table .ant-table-body .ant-table-thead > tr > th:last-child {
  padding-right: 30px;
}
.pay-roll-transfers .pay-roll-table .ant-table .ant-table-body .ant-table-tbody > tr > td {
  padding: 28px 20px;
}
.pay-roll-transfers .pay-roll-table .ant-table .ant-table-body .ant-table-tbody > tr > td .pay-roll-limited-width {
  overflow-x: hidden;
  display: block;
  width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (min-width: 1200px) {
  .pay-roll-transfers .pay-roll-table .ant-table .ant-table-body .ant-table-tbody > tr > td .pay-roll-limited-width {
    width: 180px;
  }
}
@media (min-width: 1440px) {
  .pay-roll-transfers .pay-roll-table .ant-table .ant-table-body .ant-table-tbody > tr > td .pay-roll-limited-width {
    width: auto;
  }
}
.pay-roll-transfers .pay-roll-table .ant-table .ant-table-body .ant-table-tbody > tr > td:first-child {
  padding-left: 30px;
}
.pay-roll-transfers .pay-roll-table .ant-table .ant-table-body .ant-table-tbody > tr > td:last-child {
  padding-right: 30px;
}
.pay-roll-transfers .pay-roll-table .ant-table .ant-table-footer {
  padding: 17px 30px;
  text-align: right;
}

.ant-popover.context-menu-popover {
  margin-left: 250px;
}
.ant-popover.context-menu-popover .ant-popover-inner a {
  display: block;
}
.ant-popover.context-menu-popover .ant-popover-inner a button {
  width: 100%;
  text-align: left;
}
.ant-popover.context-menu-popover .ant-popover-inner button {
  display: block;
  width: 100%;
  text-align: left;
}

.field-number-tool-tip {
  cursor: help;
  border-bottom: 1.5px var(--sally-form-label) dotted;
}

