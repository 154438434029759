@use 'sass:math';

@media screen and (max-width: 780px) {
    body #root {
        height: 100%;
    }
}

.login.ant-card.ant-card-bordered, .login.ant-card.ant-card-bordered:hover {
    $width: 376px;
    $height: 458px;
    position: fixed;
    top: 50%;
    left: 50%;
    width: $width;
    min-height: $height;
    margin: math.div(-$height, 2) 0 0 math.div(-$width, 2);
    border: 0;

    @media screen and (max-width: 780px) {
        position: unset;
        top: unset;
        left: unset;
        width: 100%;
        margin: 0 auto;
        height: 100%;
    }

    .ant-card-body {
        min-height: $height;
        width: $width;
        padding: 26px 34px 26px 34px;
        border: 1px solid var(--sally-card-border);
        border-radius: 4px;
        box-shadow: 0 3px 19px var(--sally-login-shadow);

        @media screen and (max-width: 780px) {
            height: 100%;
            width: 100%;
        }

        .h1 {
            margin-bottom: 23px;
        }
        .form-error {
            margin-top: -20px;
            margin-bottom: 0px;
        }
        .login-form-forgot {
            float: right;
            line-height: 1.5;
            font-size: 14px;
        }
        .ant-checkbox-wrapper {
            display: block;
            width: 50%;
            line-height: 1.5;
        }
        .ant-btn {
            clear: both;
            width: 100%;
            margin-top: 20px;

            &[type="submit"].ant-btn-primary {
                display: block;
                width: 100%;
                margin-top: 20px;
                padding-right: 0;
                padding-left: 0;

                &:after {
                    content: '';
                    display: none;
                }
            }
        }
        p {
            margin-top: 20px;
            margin-bottom: 0;
            text-align: right;
        }
    }
    &:hover {
        border: 0;
    }
}
