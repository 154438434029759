.employees-add {
    .employees-add-overview {
        .ant-form-item-control .ant-btn + .ant-btn {
            margin-left: 10px;
        }
        .h2 {
            clear: none;
        }
        .employees-add-bank-logo {
            padding-top: 3px;
            max-width: 210px;
            max-height: 30px;
        }
    }
}
.country-code-phone-group.ant-input-group {
    display: flex;
    align-content: stretch;

    & > * {
        display: block;

        &:first-child {
            width: 80px;
        }
        &:last-child {
            flex: 1 0 auto;
            width: auto;
        }
    }
}
