.companies-single {
    .companies-single-main {
        max-width: 1240px;
        margin-right: auto;
        margin-left: auto;
        padding: 55px 0 0 0;

        .companies-single-form {
            position: relative;
            margin-bottom: 20px;

            .ant-btn {
                &.ant-btn-secondary {
                    width: auto;
                }
            }
        }
        .h1 {
            margin-top: 0;
        }
        .title-menu {
            a, .ant-btn {
                & + * {
                    margin-left: 20px;
                }
            }
        }
        .ant-spin-nested-loading {
            margin-bottom: 0;
        }
        .ant-card.ant-card-bordered.no-contract-card, .ant-card.ant-card-bordered.has-package-card {
            .ant-card-body {
                background-position: 30px center;
                background-repeat: no-repeat;
                background-size: 40px 40px;

                p {
                    margin: 0;
                    font-size: 14px;
                }
                a {
                    position: absolute;
                    top: 50%;
                    right: 40px;
                    display: inline-block;
                    line-height: 20px;
                    margin-top: -10px;
                    padding-right: 15px;
                    background-position: right center;
                    background-repeat: no-repeat;
                    background-size: 12px 12px;
                    font-size: 14px;
                    font-weight: 500;
                }
            }
            .ant-card-body, &:hover .ant-card-body {
                padding: 25px 110px 25px 90px;
            }
        }
        .ant-card.ant-card-bordered.has-package-card {
            .ant-card-body {
                background-position: 30px center;
                background-repeat: no-repeat;
                background-size: 40px 40px;

                p {
                    margin: 0;
                    font-size: 14px;
                }
                a, .ant-btn {
                    position: absolute;
                    top: 50%;
                    right: 40px;
                    margin-top: -10px;
                }
                a {
                    display: inline-block;
                    line-height: 20px;
                    padding-right: 15px;
                    background-position: right center;
                    background-repeat: no-repeat;
                    background-size: 12px 12px;
                    font-size: 14px;
                    font-weight: 500;
                }
                .ant-btn {
                    margin-top: -18px;
                    padding-right: 14px;
                }
            }
            .ant-card-body, &:hover .ant-card-body {
                padding: 25px 170px 25px 90px;
            }
        }
        .ant-card.ant-card-bordered.package-base-card {
            &, &:hover {
                .ant-card-body {
                    .package-base-card-header {
                        .package-base-card-title {
                            font-size: 20px;
                            font-weight: 700;

                            em {
                                font-style: normal;
                                color: var(--sally-ad-highlight);
                            }
                        }
                        .package-base-card-subtitle {
                            font-size: 16px;
                            color: var(--sally-grey-shaded);
                        }
                        .package-base-card-info {
                            float: right;

                            .ant-btn {
                                margin: 5px 0 0 20px;
                            }
                            a {
                                font-size: 15px;
                            }
                        }
                    }
                    .ant-row {
                        margin-top: 40px;

                        & > * {
                            font-size: 15px;

                            strong {
                                display: block;
                                margin-bottom: 5px;
                                border-bottom: 1px solid var(--sally-ad-highlight);
                                font-weight: 700;
                                font-size: 17px;
                                color: var(--sally-ad-highlight);
                            }
                        }
                    }
                }
            }
        }
        .ant-card.ant-card-bordered.package-addon-card {
            .ant-card-body {
                height: 150px;

                .ant-switch-wrapper, .ant-link-box {
                    position: absolute;
                    top: 50%;
                    left: 10px;
                    margin-top: -50px;
                    padding: 30px 50px;
                    border-right: 1px solid var(--sally-card-border);
                }
                .ant-link-box {
                    margin-top: -60px;
                    padding: 30px 10px;
                    font-size: 14px;
                    width: 150px;
                    box-sizing: border-box;
                    text-align: center;
                }
                .h2 {
                    margin: 5px 0 0 0;
                    font-weight: 500;

                    span {
                        font-weight: 300;
                    }
                }
                p {
                    margin: 0;
                    line-height: 22px;
                    font-size: 14px;

                    a {
                        font-size: 16px;
                        font-weight: 500;
                        color: var(--sally-ad-highlight);
                    }
                }
                .package-addon-card-icon {
                    position: absolute;
                    top: 50%;
                    right: 220px;
                    margin-top: -23px;
                    width: 46px;
                    height: 46px;
                }
                .package-addon-card-price {
                    position: absolute;
                    top: 50%;
                    right: 10px;
                    width: 185px;
                    margin-top: -50px;
                    padding: 15px 30px;
                    border-left: 1px solid var(--sally-card-border);
                    font-size: 16px;

                    span {
                        font-weight: 500;
                        color: var(--sally-ad-highlight-price);
                    }
                    em {
                        font-size: 12px;
                        font-style: normal;
                    }
                    &.price-four-lines {
                        line-height: 20px;
                    }
                }
            }
            .ant-card-body, &:hover .ant-card-body {
                padding: 25px 400px 25px 210px;
            }
        }
    }
    .h3 {
        line-height: inherit;

        > .user-image {
            float: left;
            margin: 3px 18px 0 0;
        }
        a {
            display: inline-block;
            padding: 5px 0;
            font-size: 10pt;
        }
    }
}

.company-logo {
    position: relative;

    .logo {
        margin: 0 auto 20px auto;
        width: 308px;
        border: 4px solid var(--sally-card-border);

        img {
            max-width: 300px;
        }
    }
    .ant-upload.ant-upload-drag {
        display: inline-block;
        width: auto;
        height: auto;
    }
    .ant-btn {
        margin-right: 10px;
        margin-left: 10px;
        padding-right: 14px;
    }
}

.excel-employees-import {
    .ant-alert {
        margin-top: 20px;
        margin-bottom: 0px;
    }
    a, .ant-btn {
        & + .ant-btn {
            margin-left: 10px;
            padding-left: 20px;
            font-size: 14px;
        }
    }
    .ant-upload.ant-upload-drag {
        display: inline-block;
        width: inherit;
    }
    .preview-table {
        font-size: 14px;

        .errors {
            margin-top: -10px;
            margin-bottom: 20px;
        }
        .ant-row {
            margin-bottom: 10px;
        }
    }
}
.vacation-calendar-form {
    max-width: 600px;

    .vacation-set-active {
        float: right;
    }

    .calendar-add-day-form {
        margin-top: 10px;

        .calendar-add-day {
            display: inline-block;
            margin-top: 2px;
            font-size: 14px;
            font-weight: 500;
            color: var(--sally-link);
            cursor: pointer;

            .icon {
                margin: 0 2px -5px 0;
            }
        }
    }
}
.auto-approve-reimbursement-employees {
    .reimbursement-limit-employee {
        width: 300px;
    }
    .reimbursement-limit-amount {
        max-width: 70%;
    }

    .ant-table-row {
        min-height: 50px;

        .reimbursement-limit-amount {
            .reimbursement-limit-display {
                cursor: pointer;
            }

            .reimbursement-limit-form {
                display: flex;
                justify-content: space-between;

                & > * {
                    flex-grow: 0.5;
                }

                .reimbursement-limit-buttons {
                    display: flex;
                    justify-content: flex-end;

                    .reimbursement-limit-remove {
                        min-width: 100px;
                        padding: 0 15px;
                    }

                    .ant-btn {
                        margin-top: 25px !important;
                    }

                    .reimbursement-limit-toggle {
                        margin-top: 32px;
                    }
                }
            }
        }
    }
}

.companies-single .advanced-settings .ant-col.setting-cell {
    &:target, &.target-element {
        border-radius: 5px;
        background-color: var(--sally-target-element-background);
        &, p, label {
            color: var(--sally-target-element-text);
        }
    }
}