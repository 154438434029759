.employee-batch-employee {
  position: relative;
  width: 100%;
  padding: 8px 8px 8px 16px;
  border: 1px solid var(--sally-white-shaded);
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(57, 85, 106, 0.02);
  font-size: 14px;
  font-weight: 500;
  color: var(--sally-black);
  margin-top: 8px;
}
.employee-batch-employee:first-child {
  margin-top: 0;
}
.employee-batch-employee button {
  padding-right: 12px;
}
.employee-batch-employee.success .h3, .employee-batch-employee.no-change .h3, .employee-batch-employee.failure .h3, .employee-batch-employee.failure a {
  color: var(--sally-white);
}
.employee-batch-employee .message {
  font-weight: bold;
}

.employee-import-result.success, .employee-import-result.success:hover {
  background-color: var(--sally-green);
  border-color: var(--sally-green);
  color: var(--sally-white);
}
.employee-import-result.success td, .employee-import-result.success:hover td {
  color: var(--sally-white);
}
.employee-import-result.no-change, .employee-import-result.no-change:hover {
  background-color: var(--sally-orange);
  border-color: var(--sally-orange);
  color: var(--sally-white);
}
.employee-import-result.no-change td, .employee-import-result.no-change:hover td {
  color: var(--sally-white);
}
.employee-import-result.failure, .employee-import-result.failure:hover {
  background-color: var(--sally-red);
  border-color: var(--sally-red);
  color: var(--sally-white);
}
.employee-import-result.failure td, .employee-import-result.failure:hover td {
  color: var(--sally-white);
}

