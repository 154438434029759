.h1 {
    line-height: 40px;
    margin-top: 8px;
    margin-bottom: 24px;
    font-weight: 400;
    color: var(--sally-text-header);
}

.h2, .h3, .h4, .h5, .h6 {
    clear: both;
    margin: 0;
    font-weight: 400;
    color: var(--sally-text-header);
}

.h1 {
    line-height: 36px;
    font-size: 22px;
}
.h2 {
    line-height: 28px;
    font-size: 18px;
}
.h3 {
    line-height: 24px;
    font-size: 16px;
}
.h5 {
    line-height: 20px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    color: var(--sally-text-header-secondary);
}

.ant-popover.context-menu-popover {
    margin-top: -5px;
    margin-right: 10px;
    padding-top: 0;

    .ant-popover-arrow {
        display: none;
    }
    .ant-popover-inner {
        background-color: var(--sally-popover-background);
        background-clip: border-box;
        border: 1px solid var(--sally-popover-border);
        border-radius: 3px;
        box-shadow: 0 2px 6px 0 rgba(57, 85, 106, 0.08);

        .ant-popover-inner-content {
            min-width: 175px;
            padding: 0;
            color: inherit;

            > a {
                display: block;
                line-height: 24px;
                padding: 6px 18px 6px 12px;
                border-bottom: 1px solid var(--sally-popover-border);
                font-size: 14px;
                font-weight: 500;
                color: var(--sally-popover-text);

                .icon {
                    float: left;
                    margin: 2px 10px 0 0;
                }
                &:last-child {
                    border-bottom: 0;
                }
            }
        }
    }
}

.title-menu {
    float: right;
}

.subcard {
    padding: 20px 30px 20px 30px;
    background-color: var(--sally-subcard-background);
    border: 1px solid var(--sally-subcard-border);
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgba(57, 85, 106, 0.04);
}

.user-image {
    display: inline-block;
    background-color: var(--sally-user-image-background);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    color: var(--sally-user-image-text);

    &.user-image-xsmall {
        width: 24px;
        height: 24px;
        line-height: 24px;
        font-size: 10px;
    }
    &.user-image-small {
        width: 32px;
        height: 32px;
        line-height: 32px;
        font-size: 14px;
    }
    &.user-image-medium {
        width: 36px;
        height: 36px;
        line-height: 36px;
        font-size: 16px;
    }
    &.user-image-large {
        width: 106px;
        height: 106px;
        line-height: 106px;
        font-size: 47px;
    }
}

.help-modal-toggle {
    cursor: help;
    display: inline-block;
    margin-left: 8px;

    svg {
        width: 14px;
        height: 14px;
    }
}
body .ant-modal-wrap .ant-modal.help-modal-container {
    .ant-modal-content {
        .ant-modal-close {
            margin-left: 15px;
        }
        .ant-modal-body {
            padding: 20px 25px 10px 15px;
        }
    }
}
.registration-method-alert-message {
    display: flex;
    flex-direction: row;

    .registration-method-alert-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}
.registration-method-app-alert-message {
    display: flex;
    flex-direction: row;
}