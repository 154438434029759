.employees-add .employees-add-overview .ant-form-item-control .ant-btn + .ant-btn {
  margin-left: 10px;
}
.employees-add .employees-add-overview .h2 {
  clear: none;
}
.employees-add .employees-add-overview .employees-add-bank-logo {
  padding-top: 3px;
  max-width: 210px;
  max-height: 30px;
}

.country-code-phone-group.ant-input-group {
  display: flex;
  align-content: stretch;
}
.country-code-phone-group.ant-input-group > * {
  display: block;
}
.country-code-phone-group.ant-input-group > *:first-child {
  width: 80px;
}
.country-code-phone-group.ant-input-group > *:last-child {
  flex: 1 0 auto;
  width: auto;
}

