.reimbursement-vouchers {
    .title-menu {
        a, span, .ant-btn {
            & + * {
                margin-left: 20px;
            }
        }
        .ant-btn {
            padding-right: 14px;
        }
        label {
            display: inline;
        }
    }
    .reimbursement-vouchers-table {
        th, td {
            padding-right: 19px;
            padding-left: 19px;
            word-break: break-word;
        }
        .ant-table-row {
            td {
                padding-top: 29px;
                padding-bottom: 29px;

                &.ant-table-col-thumbnail {
                    width: 1px;
                }
                &:first-child {
                    padding: 0 15px 0 15px;

                    .voucher-image {
                        max-width: 50px;
                        height: 50px;
                        border: 1px solid #dae1e8;
                        border-radius: 2px;
                        cursor: pointer;

                        img {
                            max-width: 50px;
                            height: 50px;
                        }
                    }
                }
                &:nth-child(2) {
                    padding-left: 85px;
                    background-position: 25px center;
                    background-repeat: no-repeat;
                    background-size: 32px 32px;
                }
                & > div {
                    span {
                        color: var(--sally-grey-shaded);
                    }
                    .normal {
                        font-weight: normal;
                    }
                }
                & > span {
                    a {
                        display: block;
                        color: var(--sally-black);
                    }
                }
            }
            &.reimbursement-vouchers-table-row-success {
                td:nth-child(2) {
                    color: var(--sally-green-shaded);
                }
            }
            &.reimbursement-vouchers-table-row-warning {
                td:nth-child(2) {
                    color: var(--sally-yellow-shaded);
                }
            }
            &.reimbursement-vouchers-table-row-error {
                td:nth-child(2) {
                    color: var(--sally-red-shaded);
                }
            }
            &.reimbursement-vouchers-table-row-clickable {
                cursor: pointer;
            }
        }
    }
    .h2 + .reimbursement-vouchers-table {
        margin-top: 15px;
    }
}
.reimbursement-voucher-modal {
    .reimbursement-voucher-image {
        img {
            display: block;
            width: 440px;
            max-height: 600px;
            border: 1px solid #dae1e8;
            border-radius: 4px;
        }
    }
    .ant-form .ant-form-item .ant-form-item-control .ant-radio-group {
        margin-top: 0;
        margin-bottom: 0;
    }
}
