.employee-batch-employee {
  position: relative;
  width: 100%;
  padding: 8px 8px 8px 16px;
  border: 1px solid var(--sally-white-shaded);
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(57, 85, 106, 0.02);
  font-size: 14px;
  font-weight: 500;
  color: var(--sally-black);
  margin-top: 8px;

  &:first-child {
    margin-top: 0;
  }

  & button {
    padding-right: 12px;
  }

  &.success .h3, &.no-change .h3, &.failure .h3, &.failure a {
    color: var(--sally-white);
  }

  .message {
    font-weight: bold;
  }
}
.employee-batch-deselect {

}
.employee-import-result {
  &.success, &.success:hover {
    background-color: var(--sally-green);
    border-color: var(--sally-green);
    color: var(--sally-white);
    & td {
      color: var(--sally-white);
    }
  }
  &.no-change, &.no-change:hover {
    background-color: var(--sally-orange);
    border-color: var(--sally-orange);
    color: var(--sally-white);
    & td {
      color: var(--sally-white);
    }
  }
  &.failure, &.failure:hover {
    background-color: var(--sally-red);
    border-color: var(--sally-red);
    color: var(--sally-white);
    & td {
      color: var(--sally-white);
    }
  }
}