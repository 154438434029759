.freelancers-overview .h1 {
  margin-bottom: 10px;
}
.freelancers-overview .h1 + p {
  font-size: 14px;
}
.freelancers-overview .h3 {
  line-height: inherit;
}
.freelancers-overview .h3 > .user-image {
  float: left;
  margin: 3px 18px 0 0;
}
.freelancers-overview .ant-table .ant-table-row {
  cursor: pointer;
}
.freelancers-overview .ant-table .ant-table-row td > a {
  display: inline-block;
  padding: 5px 0;
  color: var(--sally-orange);
}

