.employees-single {
    .employees-single-side {
        position: absolute;
        top: 49px;
        right: 0;
        width: 341.33px;
        padding: 0 30px 16px;
        background-color: var(--sally-submenu-background);
        border-left: 1px solid var(--sally-submenu-border);
        box-shadow: 0px 2px 6px 0px var(--sally-submenu-border);

        @at-root .site-wrapper.with-side-menu & {
            top: 0;
            padding-top: 15px;
        }
        .ant-select {
            width: 100%;
        }
        .employee-profile {
            position: relative;
            text-align: center;

            .employee-profile-edit {
                position: absolute;
                top: 35px;
                left: 80%;

                a {
                    display: block;
                    width: 40px;
                    height: 40px;
                    padding: 9px 0 0 2px;
                    border: 1px solid #dae1e8;
                    border-radius: 50%;
                    box-shadow: 0 1px 2px 0 rgba(116, 141, 166, 0.08);
                    text-align: center;
                }
            }
            .employee-profile-image {
                position: relative;
                width: 106px;
                margin: 10px auto;

                .employee-profile-image-hover {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    opacity: 0;
                    transition: opacity .3s ease;
                    width: 106px;
                    height: 106px;
                    padding: 50px 20px 0 20px;
                    background-image: url('/src/assets/images/avatar-hover-106x106.png');
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: 106px 106px;
                    border-radius: 50%;
                    line-height: 16px;
                    font-size: 13px;
                    font-weight: 500;
                    color: #fff;
                }
                .ant-upload:hover {
                    .employee-profile-image-hover {
                        opacity: 1;
                    }
                }
                .ant-upload.ant-upload-drag-hover {
                    .employee-profile-image-hover {
                        opacity: 1;
                    }
                }
            }
            p {
                font-size: 13px;
            }
            .employee-app {
                padding: 10px 0 0 0;
                text-align: center;

                .employee-app-enabled {
                    display: inline-block;
                    padding: 0 0 0 25px;
                    background-position: center left;
                    background-repeat: no-repeat;
                    background-size: 16px 16px;
                    font-weight: 500;
                    color: var(--sally-green);
                    cursor: pointer;
                }
                .ant-btn {
                    width: 100%;
                    font-size: 15px;
                }
            }
            &:after {
                content: ' ';
                display: block;
                width: 100%;
                height: 1px;
                margin-top: 20px;
                background-image: linear-gradient(to right, #fff, #dfe2e5, #fff);
            }
        }
        .employee-details, .employee-pay-slips {
            .ant-row {
                line-height: 32px;
                font-size: 14px;
                color: var(--sally-grey-shaded);

                & + .ant-row {
                    margin-top: 0;
                }
                & > * {
                    &:first-child {
                        font-weight: 500;
                        color: var(--sally-black);
                    }
                    &:last-child {
                        overflow-x: hidden;
                        text-align: right;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
        .employee-details {
            padding: 15px 0 16px 0;

            &:not(.employee-last-element):after {
                content: ' ';
                display: block;
                height: 1px;
                margin-top: 20px;
                margin-right: -30px;
                margin-left: -30px;
                background-color: #dfe2e5;
            }
        }
        .employee-switcher {
            padding: 15px 0 14px 0;

            .h2 {
                margin-bottom: 10px;
            }
            &:after {
                content: ' ';
                display: block;
                height: 1px;
                margin-top: 32px;
                margin-right: -30px;
                margin-left: -30px;
                background-color: #dfe2e5;
            }
        }
        .employee-contract-switcher {
            padding: 2px 0 16px 0;

            .h2 {
                font-size: 10pt;
                margin-bottom: 0px;
            }
            &:after {
                content: ' ';
                display: block;
                height: 1px;
                margin-top: 32px;
                margin-right: -30px;
                margin-left: -30px;
                background-color: #dfe2e5;
            }
        }
        .employee-pay-slips {
            padding: 15px 0 14px 0;

            .h2 {
                margin-bottom: 10px;
            }
            .icon {
                margin: 0 0 -5px 18px;
            }
            &:after {
                content: ' ';
                display: block;
                height: 1px;
                margin-top: 32px;
                margin-right: -30px;
                margin-left: -30px;
                background-color: #dfe2e5;
            }
        }
        .employee-actions {
            padding: 15px 0 14px 0;
            display: grid;
            grid-template-columns: 50% 50%;
            column-gap: 8px;
            row-gap: 10px;

            .ant-btn {
                margin: 0;
                width: 100%;
                padding-right: 14px;
                font-size: 15px;
            }
        }
    }
    .employees-single-main {
        max-width: 1240px;
        margin-right: auto;
        margin-left: auto;
        padding: 55px 357.33px 0 0;

        @at-root .site-wrapper.with-side-menu & {
            padding-top: 0;
        }

        .employees-single-form {
            position: relative;
            margin-bottom: 20px;

            .ant-btn {
                &.ant-btn-secondary {
                    width: auto;
                }
            }
        }
        .title-menu {
            position: relative;

            .ant-select {
                float: left;
                width: auto;
                margin-top: 0px;

                & + .ant-btn {
                    margin-left: 20px;
                }
            }
            a {
                & + a .ant-btn {
                    margin-left: 10px;
                }
            }
        }
        .h1 {
            margin-top: 0;
        }
        .employees-single-bank-logo {
            padding-top: 3px;
            max-width: 210px;
            max-height: 30px;
        }
        .ant-spin-nested-loading {
            margin-bottom: 0;
        }
        /*
        .ant-table {
            margin: 0 -30px -30px -30px;
            background-color: #fdfdfd;
            border: none;
            border-radius: 0;
            box-shadow: none;

            .ant-table-body {
                > table {
                    padding: 0;
                }
                .ant-table-thead {
                    > tr > th {
                        padding: 11px 20px;
                        font-size: 14px;
                        font-weight: 400;

                        &:first-child {
                            padding-left: 30px;
                        }
                        &:last-child {
                            padding-right: 30px;
                        }
                    }
                }
                .ant-table-tbody {
                    > tr > td {
                        padding: 28px 20px;

                        &:first-child {
                            padding-left: 30px;
                        }
                        &:last-child {
                            padding-right: 30px;
                        }
                    }
                }
            }
            .employee-table-actions {
                width: 1px;
                white-space: nowrap;
            }
        }*//*
        .ant-btn {
            &.ant-btn-edit, &.ant-btn-view, &.ant-btn-delete {
                min-width: 42px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 16px 16px;
            }
            &.ant-btn-edit {
            }
            &.ant-btn-view {
            }
            &.ant-btn-delete {
                padding-right: 14px;
            }
        }*/
        .ant-row > * {
            font-size: 14px;
            color: var(--sally-grey-shaded);

            strong {
                font-weight: 500;
                color: var(--sally-black);
            }
        }
        .employees-single-overview-tab {
            .stat-large {
                margin-top: 10px;
                font-size: 35px;
                font-weight: 300;
                color: var(--sally-black);
            }
            .stat-small {
                font-size: 14px;
                font-weight: 400;
                color: var(--sally-grey-shaded);
            }
            .stat-extra {
                font-weight: 500;
                color: var(--sally-green);
            }
            img {
                width: 100%;
                margin-top: 10px;
            }
        }
        .employees-single-employment-tab {
            .ant-table {
                margin-top: 30px;

                span > em {
                    font-weight: 400;
                    font-style: normal;
                    color: var(--sally-grey-shaded);
                }
            }
            .h1 + .ant-table-wrapper {
                .ant-table {
                    margin-top: 0;
                }
            }
        }
        .time-registration-stat {
            margin-bottom: 20px;

            strong {
                display: block;
                font-size: 30px;
                font-weight: 300;
                color: var(--sally-black);
            }
            span {
                display: block;
                margin-top: -3px;
                color: #718294;

                &.time-registration-hours, &.time-registration-sickness, &.time-registration-vacation {
                    &:before {
                        content: '';
                        display: inline-block;
                        width: 6px;
                        height: 6px;
                        margin: 0 7px 2px 0;
                        border-radius: 50%;
                    }
                }
                &.time-registration-hours {
                    &:before {
                        background-color: var(--sally-blue);
                    }
                }
                &.time-registration-sickness {
                    &:before {
                        background-color: var(--sally-red);
                    }
                }
                &.time-registration-vacation {
                    &:before {
                        background-color: var(--sally-orange);
                    }
                }
            }
        }
        .time-registration-buttons, .salary-registration-buttons {
            text-align: right;

            .ant-btn {
                width: 150px;

                & + .ant-btn {
                    margin-top: 10px;
                }

                &.register-time-registration {
                    width: 175px;
                }
            }
        }
        .time-registration-table-approved, .one-time-pay-table-approved, .salary-registration-table-approved {
            width: 1px;
            text-align: center;
            white-space: nowrap;

            i {
                background-position-y: 5px;
            }
        }
    }
    &.section-documents {
        .employees-single-main {
            max-width: 1500px;
        }
    }
    @media (min-width: 1200px) {
        .employees-single-menu {
            right: 416px;
        }
        .employees-single-side {
            width: 416px;

            @at-root .site-wrapper:not(.with-side-menu) & {
                .employee-profile {
                    .employee-profile-edit {
                        top: 25px;
                    }

                    .employee-profile-image {
                        margin-top: -10px;
                    }
                }
            }
        }
        .employees-single-main {
            padding-right: 432px;
        }
    }
}

.ant-table {
    .table-overflow-ellipsis {
        display: inline-block;
        max-width: 200px;
        overflow-x: hidden;
        text-overflow: ellipsis;
        word-break: keep-all;
        white-space: nowrap;

        & + span.document-signed-note {
            position: relative;
            bottom: 5px;
        }
    }
    .table-approve-wrapper {
        display: flex;
        justify-content: center;
    }
}

.employment-buttons {
    text-align: right;

    .ant-btn {
        min-width: 100px;
        margin-left: 20px;
        padding-right: 14px;
    }
}
.ready-buttons {
    text-align: left;

    .ant-btn {
        min-width: 100px;
        margin-right: 20px;
        padding-right: 14px;
    }
}
.employees-car-allowance-map {
    position: relative;
    overflow-y: hidden;
    width: 440px;
    height: 290px;
    border: 1px solid #dae1e8;
    border-radius: 4px;

    .employees-car-allowance-map-inner {
        width: 440px;
        height: 340px;
        margin-top: -25px;
    }
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 4;
        opacity: 0;
    }
}
.employees-car-allowance-warning {
    width: 440px;
    margin-top: 20px;
    margin-bottom: 0;
}

.ant-card.ant-card-bordered.no-contract-card, .ant-card.ant-card-bordered.no-employment-card {
    .ant-card-body {
        background-position: 30px center;
        background-repeat: no-repeat;
        background-size: 40px 40px;

        p {
            margin: 0;
            font-size: 14px;
            color: var(--sally-grey-shaded);
        }
        a {
            position: absolute;
            top: 50%;
            right: 40px;
            display: inline-block;
            line-height: 20px;
            margin-top: -10px;
            padding-right: 15px;
            background-position: right center;
            background-repeat: no-repeat;
            background-size: 12px 12px;
            font-size: 14px;
            font-weight: 500;
        }
    }
    .ant-card-body, &:hover .ant-card-body {
        padding: 25px 110px 25px 90px;
    }
}
.employees-terminate {
    .ant-card.ant-card-bordered.employees-terminate-profile {
        margin: 20px 0;

        .ant-card-body {
            padding: 20px 24px;

            .user-image {
                float: left;
                display: block;
                margin: 4px 15px 0 0;
            }
            .employees-terminate-profile-data {
                float: left;

                p {
                    margin: 0;
                    font-size: 13px;
                    color: var(--sally-grey-shaded);
                }
            }
            &:after {
                content: ' ';
                display: block;
                clear: left;
            }
        }
    }
}

.employees-single .employees-single-actions {
    float: right;
    margin-top: 7px;
}

.employees-single .employees-single-overview {
    margin-top: 20px;
}

.employees-single .employees-single-action-button {
    float: right;
    text-align: right;
}

.employees-single .employees-single-action-button > a {
    display: block;
}

.employees-single .employees-single-action-button > a + a {
    margin-top: 5px;
}

// .employees-single .employees-single-form {
//     max-width: 600px;
// }

.employees-single .employees-single-calendar {

}

.employees-single .employees-single-calendar .events {
    line-height: 18px;
    list-style: none;
    margin: 0;
    padding: 0;
}

.employees-single .employees-single-calendar .events li {
    color: #999;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.employees-single .employees-single-calendar .events li span {
    vertical-align: middle;
}

.employees-single .employees-single-calendar .events li span:first-child {
    font-size: 9px;
    margin-right: 4px;
}

.employees-single .employees-single-calendar .event-warning {
    color: #fac450;
}

.employees-single .employees-single-calendar .event-normal {
    color: var(--sally-blue);
}

.employees-single .employees-single-calendar .event-error {
    color: #f50;
}

.employees-single .employees-single-calendar .notes-month {
    text-align: center;
}
.employees-single .employees-single-calendar .notes-month div {
    float: left;
    width: 50%;
}
.employees-single .employees-single-calendar .notes-month div section {
    font-size: 28px;
}

.employees-single-table .employees-single-table-number-cell {
    text-align: right;
}

.employees-single .employees-single-table .employees-single-table-action-cell {
    width: 1px;
    white-space: nowrap;
}

$expired_background: var(--sally-grey-faded);
$expired_border: var(--sally-grey-shaded);
$active_background: var(--sally-green-faded);
$active_border: var(--sally-green-shaded);
$future_background: var(--sally-orange-faded);
$future_border: var(--sally-orange-shaded);

.employee-contract-switcher-item.ant-select-dropdown-menu-item, .contract-history-item {
    &.ant-select-dropdown-menu-item {
        border-color: inherit;
    }
    &.expired {
        background: $expired_background;
        &.contract-history-item {
            border-color: $expired_border;
        }
        &.ant-select-dropdown-menu-item-active {
            background: #a3a3a3;
        }
    }
    &.active {
        background: $active_background;
        &.contract-history-item {
            border-color: $active_border;
        }
        &.ant-select-dropdown-menu-item-active {
            background: var(--sally-green);
        }
    }
    &.future {
        background: $future_background;
        &.contract-history-item {
            border-color: $future_border;
        }
        &.ant-select-dropdown-menu-item-active {
            background: var(--sally-orange);
        }
    }
}

.contract-history-item {
    border: 1px solid var(--sally-grey-shaded);
    border-radius: 3px;
    padding: 12px 0px;

    &.ant-row.expired > *,
    &.ant-row.active > *,
    &.ant-row.future > * {
        color: var(--sally-black);
    }

    & .contract-change {
        display: block;
        &.increase {
            color: var(--sally-green-shaded);
        }
        &.decrease, &.remove {
            color: var(--sally-red-shaded);
        }
    }
    & .contract-no-change {
        font-style: italic;
    }
    & .contract-change-line {
        display: block;
        font-size: 9pt;
        line-height: 12pt;
        margin-top: -2px;
        color: var(--sally-black-faded);
    }
    & .ant-btn {
        float: right;
    }
}
body .ant-btn.ant-btn-sm.ant-btn-unlock,
body .ant-btn.ant-btn-sm.ant-btn-lookup, {
    margin-top: 24px;
    height: 38px;
    width: 100%;
    background-color: var(--sally-white-faded);
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: 50% 50%;

    &.ant-btn-unlock {

        &.unlocked {
            background-color: var(--sally-white);
        }
    }

    &.ant-btn-lookup {
        background-color: var(--sally-white);
    }

    &[disabled] {
        background-color: var(--sally-white-faded);
    }
}
.ant-card .h1 {
    .subtitle-link {
        display: block;
        font-size: 10pt;
        margin-top: -10px;
    }
}

body .created-at-note {
    font-size: 8pt;
    font-style: italic;
    color: var(--sally-grey);
}