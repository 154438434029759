.one-time-pays {
    .title-menu {
        a, span, .ant-btn {
            & + * {
                margin-left: 20px;
            }
        }
        .ant-input-search.ant-input-affix-wrapper {
            width: inherit;

            .ant-input {
                width: 200px;
            }
        }
        a + a .ant-btn {
            padding-left: 20px;
            font-size: 14px;
        }
    }
    .h1 {
        margin-bottom: 10px;

        & + p {
            font-size: 14px;
        }
    }
    .h3 {
        line-height: inherit;

        > .user-image {
            float: left;
            margin: 3px 18px 0 0;
        }
    }
    .ant-table .ant-table-row {
        cursor: pointer;

        td > a {
            display: inline-block;
            padding: 5px 0;
            color: var(--sally-orange);
        }
    }
}
