.button-row {
  clear: both;
  float: right;
  display: flex;
}
.button-row .ant-btn {
  margin-left: 10px;
}
.button-row .back-button {
  float: left;
}

.staged-import-container .ant-radio-wrapper {
  float: left;
  clear: left;
}
.staged-import-container .staged-import-period-select {
  width: 50%;
}

.staged-import-period-form {
  margin-bottom: 25px;
}

.staged-import-results {
  clear: both;
}
.staged-import-results .staged-import-errors, .staged-import-results .staged-import-employee-errors {
  background-color: var(--sally-red-faded);
}
.staged-import-results .staged-import-errors .staged-import-errors-error.ant-row, .staged-import-results .staged-import-errors .staged-import-employee-errors-error.ant-row, .staged-import-results .staged-import-employee-errors .staged-import-errors-error.ant-row, .staged-import-results .staged-import-employee-errors .staged-import-employee-errors-error.ant-row {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}
.staged-import-results .staged-import-employee-errors {
  border-radius: 5px;
  padding: 5px 15px;
}
.staged-import-results .staged-import-employee {
  border: #cad1d9 1px solid;
  border-radius: 5px;
}
.staged-import-results .staged-import-employee + .staged-import-employee {
  margin-top: 15px;
}
.staged-import-results .staged-import-employee.success {
  border-color: var(--sally-green);
}
.staged-import-results .staged-import-employee.success .staged-import-employee-headline {
  background-color: var(--sally-green);
  color: #fff;
}
.staged-import-results .staged-import-employee.no-change {
  border-color: var(--sally-orange);
}
.staged-import-results .staged-import-employee.no-change .staged-import-employee-headline {
  background-color: var(--sally-orange);
  color: #fff;
}
.staged-import-results .staged-import-employee.failure {
  border-color: var(--sally-red);
}
.staged-import-results .staged-import-employee.failure .staged-import-employee-headline {
  background-color: var(--sally-red);
  color: #fff;
}
.staged-import-results .staged-import-employee .staged-import-employee-headline {
  background: #cad1d9;
  padding: 5px 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
}
.staged-import-results .staged-import-employee .staged-import-employee-headline .user-image {
  margin-right: 15px;
}
.staged-import-results .staged-import-employee .staged-import-employee-headline small {
  color: #000;
}
.staged-import-results .staged-import-employee .staged-import-employee-headline .staged-import-employee-headline-details a {
  color: #fff;
}
.staged-import-results .staged-import-employee .staged-import-employee-details {
  margin-top: 5px;
  margin-left: 50px;
  padding: 5px 15px;
}
.staged-import-results .staged-import-employee .staged-import-employee-details .staged-import-employee-errors + div {
  margin-top: 15px;
}
.staged-import-results .staged-import-employee .staged-import-employee-list > div {
  display: list-item;
  margin-left: 15px;
}

