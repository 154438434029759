.package-upgrade.ant-card.ant-card-bordered {
    &, &:hover {
        .ant-card-body {
            padding-top: 50px;
            text-align: center;

            .logo-fill {
                fill: var(--sally-upgrade-logo);
            }
            h1 {
                display: block;
                margin: 0;
                font-size: 30px;
                color: var(--sally-ad-modal-title);
            }
            .package-upgrade-subtitle {
                margin-top: 20px;
                font-size: 17px;
                color: var(--sally-ad-modal-subtitle);
            }
            .package-upgrade-price {
                margin-top: 50px;
                font-size: 100px;
                font-weight: 200;
                color: var(--sally-ad-highlight-price);
            }
            .package-upgrade-price-details {
                margin-top: -25px;
                margin-bottom: 50px;
                font-size: 15px;
                color: var(--sally-ad-modal-subtitle);
            }
            .ant-row {
                margin-top: 100px;

                & > * {
                    font-size: 15px;
                    color: var(--sally-ad-modal-subtitle);

                    strong {
                        display: block;
                        margin-bottom: 5px;
                        font-size: 17px;
                        font-weight: normal;
                        color: var(--sally-ad-modal-title);
                    }
                }
                .ant-col {
                    padding-left: 5px;
                    padding-right: 5px;
                }
            }
        }
    }
    &.premium-upgrade, &.premium-upgrade:hover {
        .ant-card-body {
            .package-upgrade-subtitle {
                margin-top: 10px;
            }
            .package-upgrade-price {
                margin-top: 20px;
            }
            .package-upgrade-price-details {
                margin-bottom: 40px;
            }
            .ant-row {
                margin-top: 50px;
            }
        }
    }
}
