@media screen and (max-width: 780px) {
  body #root {
    height: 100%;
  }
}
.login.ant-card.ant-card-bordered, .login.ant-card.ant-card-bordered:hover {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 376px;
  min-height: 458px;
  margin: -229px 0 0 -188px;
  border: 0;
}
@media screen and (max-width: 780px) {
  .login.ant-card.ant-card-bordered, .login.ant-card.ant-card-bordered:hover {
    position: unset;
    top: unset;
    left: unset;
    width: 100%;
    margin: 0 auto;
    height: 100%;
  }
}
.login.ant-card.ant-card-bordered .ant-card-body, .login.ant-card.ant-card-bordered:hover .ant-card-body {
  min-height: 458px;
  width: 376px;
  padding: 26px 34px 26px 34px;
  border: 1px solid var(--sally-card-border);
  border-radius: 4px;
  box-shadow: 0 3px 19px var(--sally-login-shadow);
}
@media screen and (max-width: 780px) {
  .login.ant-card.ant-card-bordered .ant-card-body, .login.ant-card.ant-card-bordered:hover .ant-card-body {
    height: 100%;
    width: 100%;
  }
}
.login.ant-card.ant-card-bordered .ant-card-body .h1, .login.ant-card.ant-card-bordered:hover .ant-card-body .h1 {
  margin-bottom: 23px;
}
.login.ant-card.ant-card-bordered .ant-card-body .form-error, .login.ant-card.ant-card-bordered:hover .ant-card-body .form-error {
  margin-top: -20px;
  margin-bottom: 0px;
}
.login.ant-card.ant-card-bordered .ant-card-body .login-form-forgot, .login.ant-card.ant-card-bordered:hover .ant-card-body .login-form-forgot {
  float: right;
  line-height: 1.5;
  font-size: 14px;
}
.login.ant-card.ant-card-bordered .ant-card-body .ant-checkbox-wrapper, .login.ant-card.ant-card-bordered:hover .ant-card-body .ant-checkbox-wrapper {
  display: block;
  width: 50%;
  line-height: 1.5;
}
.login.ant-card.ant-card-bordered .ant-card-body .ant-btn, .login.ant-card.ant-card-bordered:hover .ant-card-body .ant-btn {
  clear: both;
  width: 100%;
  margin-top: 20px;
}
.login.ant-card.ant-card-bordered .ant-card-body .ant-btn[type=submit].ant-btn-primary, .login.ant-card.ant-card-bordered:hover .ant-card-body .ant-btn[type=submit].ant-btn-primary {
  display: block;
  width: 100%;
  margin-top: 20px;
  padding-right: 0;
  padding-left: 0;
}
.login.ant-card.ant-card-bordered .ant-card-body .ant-btn[type=submit].ant-btn-primary:after, .login.ant-card.ant-card-bordered:hover .ant-card-body .ant-btn[type=submit].ant-btn-primary:after {
  content: "";
  display: none;
}
.login.ant-card.ant-card-bordered .ant-card-body p, .login.ant-card.ant-card-bordered:hover .ant-card-body p {
  margin-top: 20px;
  margin-bottom: 0;
  text-align: right;
}
.login.ant-card.ant-card-bordered:hover, .login.ant-card.ant-card-bordered:hover:hover {
  border: 0;
}

