.one-time-pays .title-menu a + *, .one-time-pays .title-menu span + *, .one-time-pays .title-menu .ant-btn + * {
  margin-left: 20px;
}
.one-time-pays .title-menu .ant-input-search.ant-input-affix-wrapper {
  width: inherit;
}
.one-time-pays .title-menu .ant-input-search.ant-input-affix-wrapper .ant-input {
  width: 200px;
}
.one-time-pays .title-menu a + a .ant-btn {
  padding-left: 20px;
  font-size: 14px;
}
.one-time-pays .h1 {
  margin-bottom: 10px;
}
.one-time-pays .h1 + p {
  font-size: 14px;
}
.one-time-pays .h3 {
  line-height: inherit;
}
.one-time-pays .h3 > .user-image {
  float: left;
  margin: 3px 18px 0 0;
}
.one-time-pays .ant-table .ant-table-row {
  cursor: pointer;
}
.one-time-pays .ant-table .ant-table-row td > a {
  display: inline-block;
  padding: 5px 0;
  color: var(--sally-orange);
}

